<template>
  <div class="flex justify-center">
    <div class="lg:w-2/3 md:w-full">
      <div>
        <div class="flex justify-center">
          <img alt="Vue logo" width="300" src="@/assets/logo.jpg" />
        </div>

        <!-- <div class="text-4xl">판테온게임즈</div> -->
        <!-- <div class="text-4xl">글로벌 모바일 소통 트랜드</div> -->
        <div class="tw-bar"></div>

        <div class="tw-section">
          <div class="flex justify-center m-4">
            <img class="rounded-3xl w-64 h-64" src="/store_icon.png" /><br />
          </div>

          <div class="text-2xl">
            탕탕! 팅겨! 팅고!<br />
            Pinball War!<br />
          </div>

          <div class="flex justify-center">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.DefaultCompany.PinballWar_Dummy&hl=ko"
              >
                <img
                  class="rounded-3xl w-32"
                  src="@/assets/google_play_download.png"
                />
              </a>
            </div>

            <div class="ml-4 w-32 flex justify-center items-center">
              <div class="border-2 rounded-xl px-2 py-1">iOS 준비중</div>
            </div>
          </div>
        </div>

        <div class="tw-bar"></div>

        <div class="tw-section">
          <div class="text-2xl">팅고 제품들</div>

          <div class="flex justify-center m-4">
            <img class="rounded-xl w-64 h-64" src="@/assets/product-hood.jpg" />
          </div>

          <div class="flex justify-center">
            <div class="w-2/3">
              <a
                href="https://marpple.shop/kr/designsome?cate_list_id=9"
                target="_blank"
              >
                <div class="tw-go-button" @click="onStore">제품 보러가기</div>
              </a>
            </div>
          </div>
        </div>

        <div class="tw-bar"></div>
      </div>
    </div>
  </div>

  <!-- <div>
    <a href="http://google.com" target="_blank">
      <button @click="console.log('a')">button</button>
    </a>
  </div> -->

  <!-- <div class="m-4"></div>
  <div>
    <button @click="onGtagGranted()">광고 추적 동의</button>
  </div> -->

  <div class="m-4"></div>

  <div class="flex justify-between">
    <div class="text-left ml-4 mb-4 text-xs text-gray-400">
      판테온게임즈 / Pantheon Games Co. Ltd.<br />
      사업자등록번호: 872-88-02911<br />
      대표자명: 천승진 / Chun seung jin<br />
      <!-- <div>주소: 경기도 부천시 소사구 옥길로 55 옥길센트리뷰</div> -->
      e-mail: devteam@ptogames.com<br />
      웹 버전: {{ web_version }}
    </div>
    <div class="text-gray-500">
      <div @click="onAgree">준비중</div>
    </div>
    <div class="mx-4">
      <a
        href="https://www.instagram.com/pantheon_games1?igsh=bmM5cHNyaThpcmE2"
        target="_blank"
      >
        <img src="@/assets/Instagram_icon.png" class="w-8 h-8" />
      </a>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { onMounted, ref } from "vue";
// import { pto_get_url } from "@/global";

// 심사전 빌드
// const unity_demo_url = "https://sidhost001.cafe24.com/unity/z_build_webgl";
// const unity_demo_desc = "빌드 플레이 하기 (1.1 / 2024-03-06)";
// const web_version = "v1.5 (2024-03-09)";

// // 2024-03-11 심사중 빌드
// // 버튼 이미지 붙이기
// // 결과창
// const web_version = "v1.5 (2024-03-09)";
// const unity_demo_url = "https://sidhost001.cafe24.com/unity/z_build_webgl_1_2";
// const unity_demo_desc = "빌드 플레이 하기 (v1.2 / 2024-03-11)";
// const unity_demo_old_title = "진행중인 사항 v1.3";
// const unity_demo_old_list = [
//   { id: 1, text: "국기를 제일 위에 표시" },
//   { id: 1, text: "진영 3개 -> 4개" },
//   { id: 1, text: "AI 밸런스 선택<" },
//   { id: 1, text: "국기 뺏으면 팀 폭파" },
//   { id: 1, text: "1팀만 남으면 종료" },
//   { id: 1, text: "결과창 점수 작업" },
//   { id: 1, text: "결과창에 프로필 사진 표시" },
// ];

// // 2024-03-22
// const web_version = "v1.6 (2024-03-22)";
// const unity_demo_url =
//   "https://sidhost001.cafe24.com/unity/z_build_webgl_1_3_1";
// const unity_demo_desc = "빌드 플레이 하기 (v1.3.1 / 2024-03-22)";
// const unity_demo_old_title = "진행중인 사항 v1.4";
// const unity_demo_old_list = [
//   //
//   { id: 1, text: "공 속성 추가" },
// ];

// // 2024-04-12
// const web_version = "v1.7.1 (2024-04-09)";
// const unity_demo_url =
//   "https://sidhost001.cafe24.com/unity/z_build_webgl_1_4_2";
// const unity_demo_desc = "빌드 플레이 하기 (v1.4.2 / 2024-04-09)";
// const unity_demo_old_title = "진행중인 사항 v1.5";
// const unity_demo_old_list = [
//   //
//   { id: 1, text: "번역 적용" },
//   { id: 2, text: "UI 작업" },
//   { id: 3, text: "재화 작업" },
// ];

// 2024-06-05
const web_version = "v1.8.0 (2024-06-05)";
const unity_demo_url =
  "https://sidhost001.cafe24.com/unity/z_build_webgl_1_6_1";
const unity_demo_desc = "빌드 플레이 하기 (v1.6.1 / 2024-06-05)";
const unity_demo_old_title = "진행중인 사항 v1.7";
const unity_demo_old_list = [
  //
  { id: 1, text: "거의 출시 가능한 수준" },
];

const gtag = window.gtag;

// default web address
// ptogames-85a9e.firebaseapp.com
// project
// ptogames-web

const reserve_show = ref(false);
const dev_process = ref({
  ok: false,
  todo: "?",
  doing: "?",
  done: "?",
});

onMounted(() => {
  document.title = "판테온게임즈 Pantheon Games";

  gtag("consent", "default", {
    ad_storage: "denied",
  });

  // gtag("event", "page_view", {
  //   event_name: 1,
  // });

  // console.log("dev_process_data", dev_process.value);

  // console.log("pto_get_url", pto_get_url);
  // fetch(pto_get_url, {
  //   method: "POST",
  //   // headers: { "Content-Type": "application/json" },
  //   // body: JSON.stringify({ title: "Vue POST Request Example" })
  // })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     if (data.code !== 100) {
  //       console.log("data fail", data);
  //       return;
  //     }

  //     console.log("data2", typeof data, data);

  //     if (!Array.isArray(data.datas)) {
  //       console.log("data.datas is not array");
  //       return;
  //     }

  //     for (var i in data.datas) {
  //       var d = data.datas[i];
  //       if (d.key == "PTO_todo") {
  //         dev_process.value.todo = d.val;
  //       } else if (d.key == "PTO_doing") {
  //         dev_process.value.doing = d.val;
  //       } else if (d.key == "PTO_done") {
  //         dev_process.value.done = d.val;
  //       }
  //     }

  //     if (
  //       dev_process.value.todo > 0 ||
  //       dev_process.value.doing > 0 ||
  //       dev_process.value.done > 0
  //     ) {
  //       dev_process.value.ok = true;
  //     }

  //     console.log("dev_process", dev_process.value);
  //   })
  //   .catch((err) => {
  //     console.log("fetch error", pto_get_url, err.message);
  //   });
});

const setReserve = (show) => {
  console.log("setReserve", show);
  reserve_show.value = show;
  gtag("event", "setReserve", {
    show: show ? 1 : 0,
  });
};

const onAgree = () => {
  console.log("onAgree");
  gtag("consent", "update", {
    ad_storage: "granted",
  });
};

const onDemo = () => {
  console.log("onDemo");
  gtag("event", "demo_view", {
    event_name: 1,
  });
};

const onStore = () => {
  console.log("onStore");
  gtag("event", "store_view", {
    event_name: 1,
  });
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  color: white;
  margin-top: 60px;
}

body {
  background: black;
}

a {
  color: white;
  font-size: x-large;
}
</style>
