import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import UploadProfileView from "../views/UploadProfileView.vue";
// import EditGameData from "../views/EditGameData.vue";
// import VerifyEmail from "../views/VerifyEmail.vue";

const routes = [
  {
    path: "/",
    component: HomeView,
  },

  // 프사 업로드
  // http://localhost:8080/upload_profile/abc
  // https://ptogames.com/upload_profile/abc
  {
    path: "/upload_profile/:token",
    // component: UploadProfileView,
    component: () => {
      return import("../views/UploadProfileView.vue");
    },
  },

  // // http://localhost:8080/edit_game_data
  // // http://ptogames.com/edit_game_data
  // {
  //   path: "/edit_game_data",
  //   // component: EditGameData,
  //   component: () => {
  //     return import("../views/EditGameData.vue");
  //   },
  // },

  // 이메일 인증
  // http://localhost:8080/email_verify/270638c4cecc4bc0b9260a8b1f3267531/0
  // https://ptogames.com/email_verify/270638c4cecc4bc0b9260a8b1f3267531/0
  {
    path: "/email_verify/:send_key/:lang",
    // component: VerifyEmail,
    component: () => {
      return import("../views/VerifyEmail.vue");
    },
  },

  // 게임이용 정책 (할일)
  // http://localhost:8080/policy-game-pinballwar/0
  // https://ptogames.com/policy-game-pinballwar/0
  {
    path: "/policy-game-pinballwar/:lang",
    component: () => {
      return import("../views/ServiceTermView.vue");
    },
  },

  // 개인정보 정책
  // http://localhost:8080/policy-pinballwar/0
  // https://ptogames.com/policy-pinballwar/0
  {
    path: "/policy-pinballwar/:lang",
    component: () => {
      return import("../views/PolicyView.vue");
    },
  },

  // 뽑기 정보
  // http://localhost:8080/box-open/0
  // https://ptogames.com/box-open/0
  {
    path: "/box-open/:lang",
    component: () => {
      return import("../views/BoxOpenView.vue");
    },
  },

  // {
  //   path: "/test/:token",
  //   component: () => {
  //     return import("../views/TestVue.vue");
  //   },
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
